import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ChevronDownIcon, UserIcon, ClockIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon, PlayIcon, ClipboardDocumentListIcon, DocumentTextIcon } from '@heroicons/react/24/solid';
import { includes } from 'lodash';

import VideoPlayerPopup from './VideoPlayerPopup';
import { convertToBanglaNumber, getBanglaDate, getBanglaTime } from '../utils';
import { getDownloadableLink } from '../services/download';
import toast from 'react-hot-toast';


export default function ContentAccordion(props) {
  const navigate = useNavigate();
  const { courseId, topics, courseContentId } = props;
  const [selectedTopicIndex, setSelectedTopicIndex] = useState(0);
  const [openVideoPlayer, setOpenVideoPlayer] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);

  useEffect(() => {
    setSelectedTopicIndex(0);
  }, [topics]);

  const onSelectContent = async (event, contentType, content, contentIndex) => {
    event.preventDefault();
    if ((content.endTime && moment(content.endTime) < moment()) ||
      (content.startTime && moment(content.startTime) > moment())) {
      return;
    } else if (contentType === 'lesson') {
      navigate(`/learn/lessons/${courseContentId}/topics/${selectedTopicIndex}/course/${courseId}`, {
        state: {
          contentIndex: contentIndex
        }
      });
    } else if (contentType === 'exam') {
      if (content.isTaken) {
        const url = `/learn/exam/${content.takenExamId}/result`;
        window.location.href = url;
      } else {
        const url = `/learn/exam/${content?.exam?._id}/course/${courseId}`;
        window.open(url, '_blank', 'location=no');
      }
    } else if (includes(['ebook', 'note'], contentType)) {
      try {
        const responseData = await getDownloadableLink({ privateLink: content.note.pdf });
        navigate(`/learn/ebooks/${content.note._id}/view`, {
          state: {
            privateLink: content.note.pdf,
            url: responseData.publicLink,
            name: content.title || content.note.name,
            isDownloadable: content?.isDownloadable || content?.note?.isDownloadable
          }
        });
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      {openVideoPlayer &&
        <VideoPlayerPopup
          lesson={selectedLesson}
          onClose={() => {
            setOpenVideoPlayer(false);
            setSelectedLesson(false);
          }}
        />}
      <div className="mx-2 space-y-4">
        {topics.map((topic, index) => {
          const contents = topic?.playlist?.lessons || topic?.contents;
          return (
            <div
              key={`accordion[${topic.name}]-index[${index}]`}
              className="overflow-hidden bg-white rounded-lg shadow"
            >
              <div
                className="flex flex-1 border-b border-gray-200 justify-between items-center bg-white px-4 py-5 sm:px-6"
                onClick={() => setSelectedTopicIndex(selectedTopicIndex !== index ? index : null)}
              >
                <h3 className={`text-base font-semibold leading-6 ${selectedTopicIndex === index ? 'text-purple-700' : 'text-gray-900'}`}>
                  {topic?.name}
                </h3>
                <ChevronDownIcon className={`h-4 w-4 ${selectedTopicIndex === index ? 'rotate-180' : ''}`} />
              </div>

              {selectedTopicIndex === index &&
                <ul role="list" className="divide-y divide-gray-200">
                  {contents?.map((content, contentIndex) => {
                    const contentType = content?.contentType || content?.lessonType;
                    return (
                      <li
                        key={`accordion-content-index[${contentIndex}]`}
                        className="cursor-pointer"
                        onClick={(event) => onSelectContent(event, contentType, content, contentIndex)}
                      >
                        <div className="block hover:bg-gray-50">
                          <div className="px-4 py-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <div className="flex w-3/4 text-ellipsis text-sm items-center space-x-2 font-medium text-gray-600">
                                {includes(['lesson', 'video', 'playlist'], contentType) ?
                                  <PlayIcon className="h-5 w-5 text-indigo-600" />
                                  :
                                  (
                                    contentType === 'exam' ?
                                      <ClipboardDocumentListIcon className="h-4 w-4 text-purple-600" />
                                      : <DocumentTextIcon className="h-4 w-4 text-yellow-600" />
                                  )}

                                <p className="flex space-x-2 items-center">
                                  <span>
                                    {content.contentType === 'lesson' ? content.lesson.title : content.contentType === 'exam' ? content.exam.name : content?.note?.name}
                                  </span>
                                  {content.isTaken && <CheckCircleIcon className="h-4 w-4 text-green-600" />}
                                </p>
                              </div>
                              <div className="ml-2 flex space-x-1 items-center text-sm text-gray-500">
                                <ClockIcon className="h-5 w-5" />
                                {contentType === 'lesson' && content?.formattedDuration &&
                                  <p>{convertToBanglaNumber(content?.formattedDuration)}</p>}

                                {contentType === 'exam' &&
                                  <p>পূর্ণমানঃ {convertToBanglaNumber(content?.exam?.totalMarks || (content?.exam?.totalQuestions * content?.exam?.marksPerQuestion))}</p>}

                                {contentType === 'note' &&
                                  <p>{convertToBanglaNumber(content?.note?.pages || '0')} পৃষ্ঠা</p>}
                              </div>
                            </div>
                            <div className="flex justify-between">
                              <div className="sm:flex">
                                {contentType === 'exam' &&
                                  <div className="flex mt-2 items-center text-sm text-gray-500">
                                    {content?.isTaken ?
                                      <span>প্রাপ্ত নম্বর: {convertToBanglaNumber(content?.marksObtained)}</span>
                                      :
                                      <span>{convertToBanglaNumber(content?.exam?.totalQuestions)}টি প্রশ্ন &bull; সময় {convertToBanglaNumber(content?.exam?.duration?.value || '0')} মিনিট</span>}
                                  </div>}
                              </div>
                            </div>
                            {contentType === 'exam' && !content?.isTaken && (moment(content.startTime) > moment() || content.endTime) &&
                              <div className="flex mt-2 items-center text-sm text-yellow-600">
                                <span>
                                  {moment(content.startTime) > moment() && <span>পরীক্ষা শুরু {getBanglaDate(content.startTime)} {getBanglaTime(content.startTime)}</span>}
                                  {content.endTime && moment(content.endTime) > moment() && <span>{moment(content.startTime) > moment() ? <span> &bull; </span> : ''}পরীক্ষা শেষ {getBanglaDate(content.endTime)} {getBanglaTime(content.endTime)}</span>}
                                  {content.endTime && moment(content.endTime) <= moment() && <span>এই পরীক্ষাটিতে অংশগ্রহণের সময় অতিবাহিত হয়েছে</span>}
                                </span>
                              </div>}
                            {contentType === 'lesson' && moment(content.startTime) > moment() &&
                              <div className="flex mt-2 items-center text-sm text-yellow-600">
                                <span>
                                  <span>ক্লাসটি {getBanglaDate(content.startTime)} {getBanglaTime(content.startTime)} এর পর হতে দেখা যাবে </span>
                                </span>
                              </div>}
                            {contentType === 'note' && moment(content.startTime) > moment() &&
                              <div className="flex mt-2 items-center text-sm text-yellow-600">
                                <span>
                                  <span>এই নোটটি {getBanglaDate(content.startTime)} {getBanglaTime(content.startTime)} এর পর হতে পড়া যাবে </span>
                                </span>
                              </div>}
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>}
            </div>
          )
        })}
      </div>
    </>
  )
};