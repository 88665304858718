import { useSelector } from 'react-redux';
import { includes } from 'lodash';
import {
  ClipboardDocumentListIcon,
  AcademicCapIcon,
  Cog6ToothIcon,
  ChartBarIcon,
  UserGroupIcon,
  UsersIcon,
  CurrencyBangladeshiIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  CircleStackIcon,
  DocumentCheckIcon,
  Square3Stack3DIcon,
  QuestionMarkCircleIcon,
  PlayIcon,
  DocumentTextIcon,
  PaintBrushIcon,
  BanknotesIcon,
  EnvelopeIcon,
  ComputerDesktopIcon,
  GlobeAltIcon,
  TrophyIcon,
  PrinterIcon
} from '@heroicons/react/24/solid';
import StackedLayout from '../layouts/StackedLayout';


export default function ServicePage() {
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const instituteState = useSelector((state) => state.institute);
  const currentInstitute = instituteState?.instituteInfo;

  return (
    <StackedLayout>
      <div className="flex justify-center pb-6">
        <h3 className="text-center text-2xl">
          {currentUser?.branch ? `${currentUser.branch.name} branch` : 'Manage your digital campus'}
        </h3>
      </div>
      <ul role="list" className="grid grid-cols-2 sm:grid-cols-8 mx-2 gap-4 cursor-pointer">
        {(includes(['owner', 'admin'], currentUser?.role) ||
          currentUser?.permissions?.canManageVideos) &&
          <ServiceItem name="Videos" url="/contents/lessons" Icon={PlayIcon} />}

        {(includes(['owner', 'admin'], currentUser?.role) ||
          currentUser?.permissions?.canManageNotes ||
          currentUser?.permissions?.canViewContents) &&
          <ServiceItem name="Notes" url="/contents/documents" Icon={DocumentTextIcon} />}

        {(includes(['owner', 'admin'], currentUser?.role) ||
          currentUser?.permissions?.canManageQuestions) &&
          <ServiceItem name="Questions" url="/contents/questions/mcq" Icon={QuestionMarkCircleIcon} />}

        {(includes(['owner', 'admin'], currentUser?.role) ||
          currentUser?.permissions?.canManageExams ||
          currentUser?.permissions?.canViewContents) &&
          <ServiceItem name="Exams" url="/contents/exams/mcq" Icon={ClipboardDocumentListIcon} />}

        {(includes(['owner', 'admin', 'branchAdmin'], currentUser?.role) ||
          currentUser?.permissions?.canManageRoutines) &&
          <ServiceItem name="Routines" url="/routines/offline" Icon={CalendarDaysIcon} />}

        {/* items for institute admin only */}
        {currentInstitute?.permissions?.offlineCoachingEnabled &&
          includes(['owner', 'admin'], currentUser?.role) &&
          <ServiceItem name="Branches" url="/branches" Icon={BuildingOffice2Icon} />}
        {includes(['owner', 'admin'], currentUser?.role) &&
          <ServiceItem name="Programs" url="/programs" Icon={Square3Stack3DIcon} />}

        {(includes(['owner', 'admin'], currentUser?.role) ||
          currentUser?.permissions?.canManageCourses) &&
          <ServiceItem name="Online Courses" url="/courses/online" Icon={ComputerDesktopIcon} />}

        {currentInstitute?.permissions?.offlineCoachingEnabled &&
          ((includes(['owner', 'admin'], currentUser?.role) ||
            currentUser?.permissions?.canManageCourses)) &&
          <ServiceItem name="Offline Courses" url="/courses/offline" Icon={AcademicCapIcon} />}

        {currentInstitute?.permissions?.offlineCoachingEnabled &&
          includes(['owner', 'admin', 'branchAdmin'], currentUser?.role) &&
          <ServiceItem name="Offline Batches" url="/branch/batches" Icon={CircleStackIcon} />}

        {(includes(['owner', 'admin'], currentUser?.role) ||
          currentUser?.permissions?.canManageStudentAdmissions) &&
          <ServiceItem name="Student Admission" url="/students" Icon={UserGroupIcon} />}

        {(includes(['owner', 'admin'], currentUser?.role) ||
          currentUser?.permissions?.canManageStudentPayments) &&
          <ServiceItem name="Student Payments" url="/student-payments/collect" Icon={CurrencyBangladeshiIcon} />}

        {(includes(['owner', 'admin', 'branchAdmin'], currentUser?.role) ||
          currentUser?.permissions?.canManageResults) &&
          <ServiceItem name="Results" url="/results/upload" Icon={DocumentCheckIcon} />}

        {/* <ServiceItem name="OMR" url="/results/upload" Icon={PrinterIcon} /> */}

        {/* {currentInstitute?.permissions?.offlineCoachingEnabled &&
        includes(['owner', 'admin'], currentUser?.role) &&
        <ServiceItem name="Coaching Setup" url="/coaching/branches" Icon={BuildingOffice2Icon} />} */}
        {(includes(['owner', 'admin'], currentUser?.role) ||
          currentUser?.permissions?.canManageCompetitions ||
          currentUser?.permissions?.canManageCompetitionParticipants) &&
          <ServiceItem name="Competitions" url="/competitions" Icon={TrophyIcon} />}

        {includes(['owner', 'admin'], currentUser?.role) &&
          <ServiceItem name="Instructors" url="/instructors" Icon={PaintBrushIcon} />}
        {(includes(['owner', 'admin', 'branchAdmin'], currentUser?.role) ||
          currentUser?.permissions?.canSendSms) &&
          <ServiceItem name="SMS" url="/sms/number" Icon={EnvelopeIcon} />}
        {includes(['owner', 'admin'], currentUser?.role) &&
          <ServiceItem name="Users" url="/settings/users" Icon={UsersIcon} />}
        {(includes(['owner', 'admin'], currentUser?.role) || currentUser?.permissions?.canManageBilling) &&
          <ServiceItem name="Billing" url="/billing" Icon={BanknotesIcon} />}
        {currentUser?.role === 'branchAdmin' &&
          <ServiceItem name="Users" url="/branch/users" Icon={UsersIcon} />}
        {includes(['owner', 'admin', 'branchAdmin'], currentUser?.role) &&
          <ServiceItem name="Reports" url="/reports/dashboard" Icon={ChartBarIcon} />}
        {includes(['owner', 'admin'], currentUser?.role) &&
          <ServiceItem name="Website Contents" url="/website-contents/web-banners" Icon={GlobeAltIcon} />}
        {includes(['owner', 'admin'], currentUser?.role) &&
          <ServiceItem name="Settings" url="/settings/institute-info" Icon={Cog6ToothIcon} />}

        {includes(['branchAdmin'], currentUser?.role) &&
          <ServiceItem name="Settings" url="/settings/branch-info" Icon={Cog6ToothIcon} />}
      </ul>
    </StackedLayout>
  )
};

function ServiceItem(props) {
  const { name, url, Icon } = props;
  return (
    <li
      className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
      onClick={() => {
        window.location.href = url;
      }}
    >
      <div className="flex flex-1 flex-col p-4">
        <div className="flex mx-auto h-12 w-12 rounded-full items-center justify-center bg-green-100">
          {name !== 'Student Admission' && <Icon className="h-6 w-6 text-green-600" />}
          {name === 'Student Admission' &&
            <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#16a34a" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
            </svg>
          }
        </div>
        <p className="mt-4 font-medium text-gray-900">{name}</p>
      </div>
    </li>
  )
};
