import { useState, useEffect } from 'react';
import { cloneDeep, includes, round } from 'lodash';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import StackedLayout from '../../layouts/StackedLayout';
import { Input } from '../../components/forms/fields';
import { SubmitButton, Button } from '../../components/buttons';
import {
  getSmsCredentials,
  updateSmsCredentials,
  createSmsCredentials
} from '../../services/institute';
import { getInstituteSms } from '../../services/instituteSms';
import { EnvelopeIcon } from '@heroicons/react/24/solid';


const adminSidebarNavigation = [
  { name: 'General information', href: '/settings/institute-info' },
  { name: 'Bkash credentials', href: '/settings/bkash-credentials' },
  { name: 'SMS configuration', href: '/settings/sms' },
  { name: 'Analytics configuration', href: '/settings/analytics' },
];

const branchSidebarNavigation = [
  { name: 'Branch information', href: '/settings/branch-info' },
  { name: 'SMS configuration', href: '/settings/sms' },
];

const defaultUpdateStates = {
  ssdTechUsername: false,
  ssdTechPassword: false
};

export default function SmsConfigurationPage() {
  const instituteState = useSelector((state) => state.institute);
  const currentInstitute = instituteState?.instituteInfo;
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const branchState = useSelector((state) => state.branch);
  const currentBranch = branchState?.branchInfo;
  const [ssdTechCredentails, setSsdTechCredentials] = useState(null);
  const [ssdTechUsername, setSsdTechUsername] = useState('');
  const [ssdTechPassword, setSsdTechPassword] = useState('');
  const [updateStates, setUpdateState] = useState(defaultUpdateStates);
  const [instituteSms, setInstituteSms] = useState(null);
  const sidebarNavigation = includes(['owner', 'admin'], currentUser?.role) ? adminSidebarNavigation : branchSidebarNavigation;

  console.log(currentBranch);

  useEffect(() => {
    async function fetchData() {
      try {

        if (includes(['owner', 'admin'], currentUser?.role)) {
          const _instituteSms = await getInstituteSms();
          setInstituteSms(_instituteSms);
        }

        const _ssdTechCredentails = await getSmsCredentials({
          gateway: 'ssdTech',
          branch: currentBranch._id
        });
        setSsdTechCredentials(_ssdTechCredentails);
        if (_ssdTechCredentails) {
          setSsdTechUsername(_ssdTechCredentails.username);
          setSsdTechPassword(_ssdTechCredentails.password);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchData();
  }, []);

  const onEdit = (event, fieldName) => {
    event.preventDefault();
    let _updateState = cloneDeep(updateStates);
    _updateState[fieldName] = true;
    setUpdateState(_updateState);
  };

  const onCancel = (event, fieldName) => {
    event.preventDefault();
    let _updateState = cloneDeep(updateStates);
    _updateState[fieldName] = false;
    setUpdateState(_updateState);
    if (fieldName === 'ssdTechUsername') { setSsdTechUsername(ssdTechCredentails?.username); }
    else if (fieldName === 'ssdTechPassword') { setSsdTechPassword(ssdTechCredentails?.password); }
  };

  const onSave = async (event, gateway, smsCredentials, fieldName, value) => {
    try {
      event.preventDefault();
      let payload = {
        gateway: gateway,
        branch: currentBranch._id
      };
      payload[fieldName] = value;

      let updatedCredentials;
      if (smsCredentials?._id) {
        updatedCredentials = await updateSmsCredentials(smsCredentials?._id, payload);
      } else {
        updatedCredentials = await createSmsCredentials(payload);
      }

      let _fieldName;

      if (updatedCredentials?.gateway === 'ssdTech') {
        setSsdTechCredentials(updatedCredentials);
        if (fieldName === 'username') {
          _fieldName = 'ssdTechUsername';
        } else if (fieldName === 'password') {
          _fieldName = 'ssdTechPassword';
        }
      }

      let _updateState = cloneDeep(updateStates);
      _updateState[_fieldName] = false;
      setUpdateState(_updateState);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="SMS configuration"
    >
      {includes(['owner', 'admin'], currentUser?.role) && currentInstitute?.permissions?.defaultSmsGatewayEnabled &&
        <div>
          <div className="mx-auto flex items-center justify-between gap-x-8 lg:mx-0 mb-10">
            <div className="flex items-center gap-x-6">
              <div className="flex mx-auto h-16 w-16 rounded-full items-center justify-center ring-1 ring-green-100">
                <EnvelopeIcon className="h-10 w-10 text-green-600" />
              </div>
              <h1>
                <div className="text-sm leading-6 text-gray-500">
                  SMS balance
                </div>
                <div className="mt-1 text-base font-semibold leading-6 text-gray-900">
                  BDT {round(instituteSms?.smsBalance, 2)}
                </div>
              </h1>
            </div>
            <div className="flex items-center gap-x-4 sm:gap-x-6">
              <SubmitButton
                label="Add balance"
                onClick={() => {
                  window.location.href = `/settings/sms/recharge`
                }}
              />
            </div>
          </div>
        </div>}
      <div className="mb-10">
        <h2 className="text-base font-semibold leading-7 text-gray-900">SSD Tech SMS gateway</h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Set credentials for SSD Tech SMS gateway
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Username</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.ssdTechUsername && <div className="text-gray-900">{ssdTechCredentails?.username}</div>}
              {updateStates.ssdTechUsername &&
                <div className="w-full">
                  <Input value={ssdTechUsername || ''} onChange={(event) => setSsdTechUsername(event.target.value)} />
                  <div className="mt-4 space-x-4">
                    <SubmitButton label="Update" onClick={(event) => onSave(event, 'ssdTech', ssdTechCredentails, 'username', ssdTechUsername)} />
                    <Button label="Cancel" onClick={(event) => onCancel(event, 'ssdTechUsername')} />
                  </div>
                </div>}

              {!updateStates.ssdTechUsername &&
                <button
                  className="font-semibold text-green-600 hover:text-green-500"
                  onClick={(event) => onEdit(event, 'ssdTechUsername')}
                >
                  Update
                </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Password</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.ssdTechPassword && <div className="text-gray-900">{ssdTechCredentails?.password}</div>}
              {updateStates.ssdTechPassword &&
                <div className="w-full">
                  <Input value={ssdTechPassword || ''} onChange={(event) => setSsdTechPassword(event.target.value)} />
                  <div className="mt-4 space-x-4">
                    <SubmitButton label="Update" onClick={(event) => onSave(event, 'ssdTech', ssdTechCredentails, 'password', ssdTechPassword)} />
                    <Button label="Cancel" onClick={(event) => onCancel(event, 'ssdTechPassword')} />
                  </div>
                </div>}

              {!updateStates.ssdTechPassword &&
                <button
                  className="font-semibold text-green-600 hover:text-green-500"
                  onClick={(event) => onEdit(event, 'ssdTechPassword')}
                >
                  Update
                </button>}
            </dd>
          </div>
        </dl>
      </div>
    </StackedLayout>
  )
};
