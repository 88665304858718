import { useEffect, useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import toast from 'react-hot-toast';
import { map } from 'lodash';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import StackedLayout from '../../layouts/StackedLayout';
import { Input, Select, InlineCheckbox, UploadWithDragDrop, TextArea } from '../../components/forms/fields';
import { getSmsCounts } from '../../utils';
import { SubmitButton, Button } from '../../components/buttons';
import ConfirmModal from '../../components/modals/ConfirmModal';
import SmsPreviewModal from './components/SmsPreviewPanel';
import { uploadLocal } from '../../services/upload';
import { sendExcelSms } from '../../services/smsEvent';


const sidebarNavigation = [
  { name: 'Numner SMS', href: '/sms/number' },
  { name: 'Excel SMS', href: '/sms/excel' },
  { name: 'Group SMS', href: '/sms/group' }
];

export default function ExcelSmsPage() {
  const smsBodyRef = useRef();
  const [smsBody, setSmsBody] = useState('');
  const [smsType, setSmsType] = useState('Text');
  const [smsCount, setSmsCount] = useState(0);
  const [characterCount, setCharacterCount] = useState(0);
  const [excelSheets, setExcelSheets] = useState(null);
  const [workBook, setWorkBook] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState(null);
  const [selectedMobileColumn, setSelectedMobileColumn] = useState(null);
  const [selectedNameColumn, setSelectedNameColumn] = useState(null);
  const [excelColumns, setExcelColumns] = useState([]);
  const [scheduledAt, setScheduledAt] = useState(null);
  const [isScheduled, setIsScheduled] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [masking, setMasking] = useState(null);
  const [maskOptions, setMaskOptions] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  useEffect(() => {
    async function _fetchData() {
    };
    _fetchData();
  }, []);

  const handleExcelUpload = (files) => {
    try {
      const data = new FormData();
      let reader = new FileReader();
      if (files.length > 0) {
        setSelectedFiles(files);
        reader.readAsArrayBuffer(files[0]);
        reader.onload = (event) => {
          let excelData = new Uint8Array(reader.result);
          let workBook = XLSX.read(excelData, { type: 'array' });
          setWorkBook(workBook);
          setExcelSheets(workBook.SheetNames);
          setSelectedSheet(null);
          setSelectedMobileColumn(null);
          setSelectedNameColumn(null);
        }
        return files[0];
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  const selectSheetValue = (event) => {
    _selectSheet(event.target.value);
  }

  const _selectSheet = (sheetName) => {
    setSelectedSheet(sheetName)
    let newSheet = workBook.Sheets[sheetName];
    let excelJSON = XLSX.utils.sheet_to_json(newSheet) ? XLSX.utils.sheet_to_json(newSheet, { defval: '' }) : null;
    let columns = excelJSON && excelJSON[0] ? Object.keys(excelJSON[0]) : [];
    setExcelData(excelJSON);
    setExcelColumns(columns);
    setSelectedMobileColumn(null);
    setSelectedNameColumn(null);
  }

  const handleSmsBodyChange = (event) => {
    let _smsBody = '';
    let smsCounts = {};
    const position = smsBodyRef.selectionStart;

    if (typeof event !== 'object') {
      let temp = cloneDeep(smsBody);
      let start = temp.substring(0, position);
      _smsBody = start.concat(event);
    } else {
      _smsBody = event.target.value;
      smsCounts = getSmsCounts(_smsBody);
    }
    setSmsBody(_smsBody);
    setCharacterCount(smsCounts.characterCount);
    setSmsCount(smsCounts.smsCount);
    setSmsType(smsCounts.smsType);
  };

  const sendSMS = async () => {
    try {
      const data = new FormData();
      if (selectedFiles.length > 0) {
        data.append('file', selectedFiles[0]);
        const responseData = await uploadLocal(data);

        const payload = {
          filePath: responseData.filePath,
          smsBody: smsBody,
          selectedSheet: selectedSheet,
          selectedMobileColumn: selectedMobileColumn,
          senderId: masking,
          scheduledAt: isScheduled ? moment(scheduledAt).zone('+06:00').format() : null
        }
        await sendExcelSms(payload);
        toast.success(`Your request is being processed.`);
      } else {
        throw new Error('No file attached');
      }
    } catch (error) {
      toast.error(error.message)
    }
  };

  const isDisabled = () => {
    return isProcessing || smsCount <= 0 ||
      !selectedSheet || !selectedMobileColumn ||
      (isScheduled && moment(scheduledAt) <= moment());
  };

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Excel SMS"
    >
      {showConfirmModal &&
        <ConfirmModal
          title={isScheduled ? 'Schedule SMS' : 'Send SMS'}
          description={`Are you sure to ${isScheduled ? 'Sschedule' : 'send'} sms? Please double check before performing this action.`}
          actionName={isScheduled ? 'Schedule SMS' : 'Send SMS'}
          onConfirm={sendSMS}
          onCancel={() => setShowConfirmModal(false)}
        />}

      {showPreviewModal &&
        <SmsPreviewModal
          smsBody={smsBody}
          isExcel={true}
          excelData={excelData}
          excelColumns={excelColumns}
          mobileColumn={selectedMobileColumn}
          onCancel={() => setShowPreviewModal(false)}
        />}

      {!showPreviewModal &&
        <>
          <div className="grid grid-cols-2 gap-x-4">
            <UploadWithDragDrop
              label="Upload excel"
              actionName="Upload"
              contentType="excel"
              content={selectedFiles?.length > 0 ? selectedFiles[0] : null}
              onUpload={(files) => handleExcelUpload(files)}
            />
            {excelSheets?.length > 0 &&
              <dl className="mt-6 space-y-4 border-gray-200 text-sm leading-6">
                <div className="sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Sheet name</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="w-full">
                      <Select value={selectedSheet} options={excelSheets} onChange={(event) => selectSheetValue(event)} />
                    </div>
                  </dd>
                </div>

                <div className="sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Mobile column</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="w-full">
                      <Select value={selectedMobileColumn} options={excelColumns} onChange={(event) => setSelectedMobileColumn(event.target.value)} />
                    </div>
                  </dd>
                </div>
              </dl>}
          </div>

          <div className="grid grid-cols-2 gap-x-8">
            <div>
              <TextArea
                ref={smsBodyRef}
                label="SMS body"
                value={smsBody}
                maxLength={smsType === "Unicode" ? 335 : 765}
                rows={6}
                onChange={handleSmsBodyChange}
              />
            </div>

            {excelColumns?.length > 0 &&
              <div className="text-sm max-h-300">
                <label className="block font-medium leading-6 text-gray-900">
                  Personalize SMS
                </label>

                <div className="mt-2 border-2 rounded-md">
                  <ul className="p-2">
                    {map(excelColumns, (column, index) =>
                      <li
                        className="pb-1 cursor-pointer hover:text-gray-400"
                        onClick={() => handleSmsBodyChange(`{{${column}[${index}]}}`)}
                      >
                        {column}
                      </li>
                    )}
                  </ul>
                </div>
              </div>}
          </div>

          <div className="grid grid-cols-3 gap-x-8">
            <div>
              <Select
                label="Mask options"
                emptyStateLabel="No Masking"
                options={maskOptions}
                value={masking}
                onChange={(event) => setMasking(event.target.value)}
              />
            </div>
            <div>
              <div className="mb-1.5">
                <InlineCheckbox
                  label="Schedule Date Time"
                  checked={isScheduled}
                  onChange={() => setIsScheduled(!cloneDeep(isScheduled))}
                />
              </div>
              <Input
                type="datetime-local"
                disabled={!isScheduled}
                value={scheduledAt || ''}
                min={moment().format('YYYY-MM-DDThh:mm')}
                onChange={(event) => setScheduledAt(event.target.value)} />
            </div>
            <div></div>
          </div>
          <div className="space-x-4">
            <SubmitButton
              label={isScheduled ? 'Schedule SMS' : 'Send SMS'}
              disabled={isDisabled()}
              onClick={() => setShowConfirmModal(true)} />

            <Button
              label="Preview"
              disabled={isProcessing}
              onClick={() => setShowPreviewModal(true)} />

          </div>
        </>}

    </StackedLayout>
  )
};
