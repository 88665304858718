import { useSelector } from 'react-redux';

import StackedLayout from '../../layouts/StackedLayout';


const sidebarNavigation = [
  { name: 'Branch information', href: '/settings/branch-info' },
  { name: 'SMS configuration', href: '/settings/sms' },
];

export default function BranchInfoPage() {
  const branchState = useSelector((state) => state.branch);
  const currentBranch = branchState?.branchInfo;

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Branch information"
    >
      <div className="mb-10">
        <h2 className="text-base font-semibold leading-7 text-gray-900">Branch Information</h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Set your information for your branch
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Name (English)</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">{currentBranch?.name}</div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Name (Bangla)</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">{currentBranch?.bnName}</div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Mobile</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">{currentBranch?.mobile}</div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Address</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">{currentBranch?.address}</div>
            </dd>
          </div>
        </dl>
      </div>
    </StackedLayout>
  )
};
