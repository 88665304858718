import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Loader from '../components/Loader';
import Breadcrumb from '../components/Breadcrumb';
import WarningAlert from '../components/alerts/WarningAlert';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import SpondonFooter from '../pages/home/spondon/components/Footer';


export default function StackedLayout(props) {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const instituteState = useSelector((state) => state.institute);
  const currentInstitute = instituteState?.instituteInfo;
  const brandColor = currentInstitute?.brandColor || process.env.REACT_APP_DEFAULT_BRAND_COLOR;

  const {
    loading,
    breadcrumbPages,
    navigation,
    navCurrent,
    sidebarNavigation,
    sidebarSecondaryNavigation,
    currentSidebarNavigation,
    currentSidebarSecondaryNavigation,
    onNavigationChange,
    onSecondaryNavigationChange,
    onAddSecondaryNavigation,
    onEditSecondaryNavigation,
    warningMessage,
    allowAnonymous,
    page,
    enrolledCourseData,
    hideFooter,
    showCustomFooter
  } = props;

  useEffect(() => {
    if (!allowAnonymous && !currentUser) { return navigate('/'); }
  });

  if (loading) {
    return (
      <Loader />
    )
  }

  return (
    <div>
      <div className="z-999">
        <Header navigation={navigation} current={navCurrent} />
      </div>

      {warningMessage &&
        <div className="mt-16">
          <WarningAlert message={warningMessage} />
        </div>}

      {breadcrumbPages &&
        <div className="mx-auto max-w-7xl mt-24 mb-6 lg:flex lg:gap-x-16 lg:px-8">
          <Breadcrumb pages={breadcrumbPages} />
        </div>}

      <div className={`${(warningMessage || breadcrumbPages) ? 'mt-6 mb-20' : 'my-20'} mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8`}>
        {sidebarNavigation &&
          <Sidebar
            page={page}
            enrolledCourseData={enrolledCourseData}
            navigation={sidebarNavigation}
            secondaryNavigation={sidebarSecondaryNavigation}
            currentNavigation={currentSidebarNavigation}
            currentSecondaryNavigation={currentSidebarSecondaryNavigation}
            onNavigationChange={onNavigationChange}
            onSecondaryNavigationChange={onSecondaryNavigationChange}
            onAddSecondaryNavigation={onAddSecondaryNavigation}
            onEditSecondaryNavigation={onEditSecondaryNavigation}
            brandColor={brandColor}
          />}

        <main className="mb-20 px-4 py-4 sm:px-6 lg:flex-auto lg:px-0">
          <div className="mx-auto max-w-2xl space-y-4 lg:mx-0 lg:max-w-none">
            {props.children}
          </div>
        </main>
      </div>
      {!hideFooter && !showCustomFooter && <Footer />}
      {showCustomFooter && currentInstitute?.subdomain === 'spondon' && <SpondonFooter />}
    </div>
  )
};
