import http from '../helpers/http';


export const getCurrentInstitute = async () => {
  try {
    const { data } = await http.get(`/api/institutes/current`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateInstitute = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/institutes/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getBkashCredentials = async () => {
  try {
    const { data } = await http.get(`/api/institutes/bkash/credentails`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createBkashCredentials = async (payload) => {
  try {
    const { data } = await http.post(`/api/institutes/bkash/credentails`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateBkashCredentials = async (bkashCredentialId, payload) => {
  try {
    const { data } = await http.put(`/api/institutes/bkash/credentails/${bkashCredentialId}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getSmsCredentials = async (params) => {
  try {
    const config = { params: params || {} }
    const { data } = await http.get(`/api/institutes/sms/credentails`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createSmsCredentials = async (payload) => {
  try {
    const { data } = await http.post(`/api/institutes/sms/credentails`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateSmsCredentials = async (smsCredentialId, payload) => {
  try {
    const { data } = await http.put(`/api/institutes/sms/credentails/${smsCredentialId}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
