export default function TeaxtArea(props) {
  const col = props?.col?.toString() || 'full';
  const rows = props.rows || 3;
  const label = props.label;
  const name = props.name;
  const value = props.value;
  const onChange = props.onChange;
  const ref = props.ref;
  const maxLength = props.maxLength;

  return (
    <div className={`col-span-${col}`}>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className={label && 'mt-2'}>
        <textarea
          ref={ref}
          name={name}
          value={value || ''}
          rows={rows}
          maxLength={maxLength}
          className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
          onChange={onChange}
        />
      </div>
    </div>
  )
}