import { useEffect, useState } from 'react';

import StackedLayout from '../../layouts/StackedLayout';


const sidebarNavigation = [
  { name: 'Numner SMS', href: '/sms/number' },
  { name: 'Excel SMS', href: '/sms/excel' },
  { name: 'Group SMS', href: '/sms/group' }
];

export default function GroupSmsPage() {

  useEffect(() => {
    async function _fetchData() {
    };
    _fetchData();
  }, []);

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Group SMS"
    >
      <div className="grid grid-cols-2">
        <h3>This section is under development</h3>
      </div>

    </StackedLayout>
  )
};
