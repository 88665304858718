import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { isEmpty } from 'lodash';

import Layout from './components/Layout';
import { resetPassword } from '../../services/user';
import { SubmitButton } from '../../components/buttons';


export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location?.state?.userId;
  const token = location?.state?.token;
  const redirectUrl = location?.state?.redirectUrl;
  const [newPassword, setNewPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (!userId || !token) { return navigate(-1); }
  }, []);

  const handleResetPassword = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      let payload = { newPassword: newPassword, token: token };
      await resetPassword(userId, payload);
      toast.success('Password reset successfully.');
      navigate(`/auth/login`, { state: { redirectUrl: redirectUrl } });
    } catch (error) {
      setUpdating(false);
      toast.error(error.message);
    }
  };

  const isDisabled = () => {
    return updating || isEmpty(newPassword) || isEmpty(confirmNewPassword) || newPassword !== confirmNewPassword;
  };

  return (
    <Layout
      title="পাসওয়ার্ড নতুন করে সেট করুন"
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6">
          <div>
            <div className="mt-2">
              <input
                type="password"
                required
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="নতুন পাসওয়ার্ড লিখুন"
                onChange={(event) => setNewPassword(event.target.value)}
              />
            </div>
          </div>
          <div>
            <div className="mt-2">
              <input
                type="password"
                required
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="পাসওয়ার্ড পুনরায় লিখুন"
                onChange={(event) => setConfirmNewPassword(event.target.value)}
              />
            </div>
          </div>
          <div>
            <SubmitButton
              label="সাবমিট করুন"
              disabled={isDisabled()}
              className="flex w-full"
              onClick={handleResetPassword}
            />
          </div>
        </form>
      </div>
    </Layout>
  )
};

