import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { includes, orderBy, cloneDeep, isEmpty, capitalize, findIndex, forEach } from 'lodash';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import * as XLSX from 'xlsx';

import StackedLayout from '../../layouts/StackedLayout';
import UserPermissionDeniedPage from '../accessControlPages/UserPermissionDenied';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { Input, Select, InlineCheckbox, UploadWithDragDrop } from '../../components/forms/fields';
import { SubmitButton } from '../../components/buttons';
import { getCoursesForBranch } from '../../services/course';
import { getBatches } from '../../services/batch';
import { getPrograms } from '../../services/program';
import { getBranches } from '../../services/branch';
import { enrollBulkCourseByAdmin } from '../../services/enrolledCourse';
import { getDiscount, getVat, handleUploadLocalFile } from '../../utils';


const sidebarNavigation = [
  { name: 'Student list', href: '/students' },
  { name: 'Student admission', href: '/students/admission' },
  { name: 'Bulk admission', href: '/students/bulk-admission' }
];

export default function BulkStudentAdmissionPage() {
  const navigate = useNavigate();
  const branchState = useSelector((state) => state.branch);
  const currentBranch = branchState?.branchInfo;
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const [courses, setCourses] = useState([]);
  const [courseType, setCourseType] = useState(null);
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [program, setProgram] = useState(null);
  const [startMonth, setStartMonth] = useState(null);
  const [totalCourseFee, setTotalCourseFee] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [specialDiscount, setSpecialDiscount] = useState(0);
  const [monthlyScholarship, setMonthlyScholarship] = useState(0);
  const [vat, setVat] = useState(0);
  const [includeVat, setIncludeVat] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [reference, setReference] = useState('');
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [discountOnAdmissionFee, setDiscountOnAdmissionFee] = useState(0);
  const [programEnrolled, setProgramEnrolled] = useState(false);
  const [excelSheets, setExcelSheets] = useState(null);
  const [workBook, setWorkBook] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState(null);
  const [selectedMobileColumn, setSelectedMobileColumn] = useState(null);
  const [selectedNameColumn, setSelectedNameColumn] = useState(null);
  const [excelColumns, setExcelColumns] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  useState(() => {
    async function _fetchData() {
      try {
        if (!currentBranch?.permissions?.canEnrollOnlineCourses) {
          setCourseType('offline');
        }

        if ((includes(['owner', 'admin'], currentUser?.role) || currentBranch?.permissions?.canAdmitCentralStudents)) {
          const _branches = await getBranches({ status: 'active' });
          setBranches(orderBy(_branches, 'name'));
        } else {
          setBranch(currentBranch);
        }

        const _programs = await getPrograms({ status: 'active' });
        setPrograms(orderBy(_programs, 'name'));
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  useEffect(() => {
    async function fetchCourses() {
      try {
        if (program && branch) {
          let params = {
            program: program._id,
            status: 'active',
            includeOnlineCourses: currentBranch?.permissions?.canEnrollOnlineCourses,
          };

          const branchId = branch?._id;
          const _courses = await getCoursesForBranch(branchId, params);
          setCourses(orderBy(_courses, 'name'));
        } else {
          setCourses([]);
          setBranch(null);
          setIncludeVat(false);
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchCourses();
    setSelectedCourses([]);
  }, [program, branch]);

  useEffect(() => {
    let _totalCourseFee = 0;
    let _discount = 0;
    let _specialDiscount = 0;
    forEach(selectedCourses, selectedCourse => {
      if (!selectedCourse.isFree) {
        const course = selectedCourse.course;
        _totalCourseFee = _totalCourseFee + course.fee;
        const courseDiscount = getDiscount(course);
        _discount = _discount + courseDiscount;
        _specialDiscount = _specialDiscount + (selectedCourse.specialDiscount || 0);
      }
    });
    setTotalCourseFee(_totalCourseFee);
    setDiscount(_discount);
    setSpecialDiscount(_specialDiscount);
  }, [selectedCourses])

  useEffect(() => {
    const courseFee = totalCourseFee || 0;
    let amount = courseFee - discount - (specialDiscount || 0);
    if (program?.paymentCircle === 'month') {
      amount = courseFee - discount - (monthlyScholarship || 0);
    }
    const _vat = getVat(amount, includeVat);
    setVat(_vat);
  }, [totalCourseFee, includeVat, discount, specialDiscount, monthlyScholarship]);

  useEffect(() => {

    let _totalAmount = totalCourseFee + vat - discount - (specialDiscount || 0);
    if (program?.paymentCircle === 'month') {
      _totalAmount = totalCourseFee + vat - discount - (monthlyScholarship || 0);
    }
    setTotalAmount(_totalAmount);
  }, [totalCourseFee, vat, discount, specialDiscount, monthlyScholarship]);



  const handleEnrollCourses = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);

      const filePath = await handleUploadLocalFile(selectedFiles);
      let payload = {
        isAdmin: true,
        filePath: filePath,
        selectedSheet: selectedSheet,
        selectedMobileColumn: selectedMobileColumn,
        selectedNameColumn: selectedNameColumn,
        specialDiscount: parseFloat(specialDiscount),
        monthlyScholarship: parseFloat(monthlyScholarship),
        reference: reference,
        currentBranch: currentBranch._id,
        programId: program._id,
        totalCourseFee: totalCourseFee,
        discount: discount,
        includeVat: includeVat,
        branch: branch?._id || currentBranch?._id,
        startMonth: startMonth,
        totalAmount: totalAmount
      };

      let _courses = [];
      forEach(selectedCourses, _course => {
        _courses.push({
          courseId: _course.course._id,
          batchId: _course.selectedBatch,
          specialDiscount: _course.specialDiscount,
          isEnrolled: _course.isEnrolled
        })
      });
      payload.courses = _courses;

      if (!programEnrolled && program.admissionFeeEnabled) {
        payload.discountOnAdmissionFee = parseFloat(discountOnAdmissionFee || 0);
      }

      const results = await enrollBulkCourseByAdmin(payload);
      navigate('/students/admission/success', {
        state: { enrolledCourses: results, isBulk: true }
      });
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const handleSelectCourse = async (course) => {
    let _selectedCourses = cloneDeep(selectedCourses);
    const courseIndex = findIndex(_selectedCourses, sc => sc.course._id.toString() === course._id.toString());
    if (courseIndex === -1) {
      let selectedCourse = { course: course };

      if (includes(['offline', 'live'], course.courseType)) {
        let params = { course: course._id, status: 'active' };
        if (course.courseType === 'offline' && branch) {
          params.branch = branch._id;
        }

        try {
          const _batches = await getBatches(params);
          selectedCourse.batches = _batches;
          selectedCourse.selectedBatch = null;
        } catch (error) {
          toast.error(error.message);
        }
      }
      _selectedCourses.push(selectedCourse);
      setSelectedCourses(_selectedCourses);
    } else {
      _selectedCourses.splice(courseIndex, 1);
      setSelectedCourses(_selectedCourses);
    }
  };

  const handleSelectBatch = (courseId, batchId) => {
    let _selectedCourses = cloneDeep(selectedCourses);
    const courseIndex = findIndex(_selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    _selectedCourses[courseIndex].selectedBatch = batchId;
    setSelectedCourses(_selectedCourses);
  };

  const handleChangeSpecialDiscount = (courseId, value) => {
    let _selectedCourses = cloneDeep(selectedCourses);
    const courseIndex = findIndex(_selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    _selectedCourses[courseIndex].specialDiscount = parseFloat(value);
    setSelectedCourses(_selectedCourses);
  };

  const getSpecialDiscountForCourse = (courseId) => {
    const courseIndex = findIndex(selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    return selectedCourses[courseIndex]?.specialDiscount;
  }

  const getCourseAmount = (course) => {
    const courseFee = course.fee;
    const _discount = getDiscount(course);
    const _specialDiscount = getSpecialDiscountForCourse(course._id) || 0;
    return courseFee - _discount - _specialDiscount;
  };

  const isCourseSelected = (courseId) => {
    const courseIndex = findIndex(selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    return courseIndex !== -1;
  }

  const isCourseEnrolled = (courseId) => {
    const courseIndex = findIndex(selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    return selectedCourses[courseIndex]?.isEnrolled;
  }

  const getCourseBatches = (courseId) => {
    const courseIndex = findIndex(selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    return selectedCourses[courseIndex].batches;
  }

  const getSelectedBatch = (courseId) => {
    const courseIndex = findIndex(selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    return selectedCourses[courseIndex].selectedBatch;
  }

  const getBatchStartMonth = (courseId) => {
    const courseIndex = findIndex(selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    const selectedCourse = selectedCourses[courseIndex];
    const _batches = selectedCourse.batches;
    const batchIndex = findIndex(_batches, batch => batch._id.toString() === selectedCourse?.selectedBatch?.toString());
    return _batches[batchIndex]?.startMonth;
  };

  const getBatchEndMonth = (courseId) => {
    const courseIndex = findIndex(selectedCourses, sc => sc.course._id.toString() === courseId.toString());
    const selectedCourse = selectedCourses[courseIndex];
    const _batches = selectedCourse.batches;
    const batchIndex = findIndex(_batches, batch => batch._id.toString() === selectedCourse?.selectedBatch?.toString());
    return _batches[batchIndex]?.endMonth;
  };

  const isDisabled = () => {
    return updating || !selectedSheet || !selectedMobileColumn || !selectedNameColumn || totalAmount < 0 ||
      monthlyScholarship > totalCourseFee ||
      selectedCourses.every(sc => sc.isEnrolled) ||
      ((specialDiscount > 0 || discountOnAdmissionFee > 0) && isEmpty(reference)) ||
      selectedCourses.some(sc => !sc.isEnrolled && includes(['offline', 'live'], sc.course.courseType) && !sc.selectedBatch) ||
      selectedCourses.some(sc => {
        const discount = getDiscount(sc.course);
        const specialDiscount = parseFloat(sc.specialDiscount);
        const discountedFee = sc.course.fee - discount;
        return specialDiscount < 0 || specialDiscount > parseFloat(discountedFee);
      }) ||
      (program.paymentCircle === 'month' && isEmpty(startMonth));
  };

  const handleExcelUpload = (files) => {
    try {
      const data = new FormData();
      let reader = new FileReader();
      if (files.length > 0) {
        setSelectedFiles(files);
        reader.readAsArrayBuffer(files[0]);
        reader.onload = (event) => {
          let excelData = new Uint8Array(reader.result);
          let workBook = XLSX.read(excelData, { type: 'array' });
          setWorkBook(workBook);
          setExcelSheets(workBook.SheetNames);
          setSelectedSheet(null);
          setSelectedMobileColumn(null);
          setSelectedNameColumn(null);
        }
        return files[0];
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  const selectSheetValue = (event) => {
    _selectSheet(event.target.value);
  }

  const _selectSheet = (sheetName) => {
    setSelectedSheet(sheetName)
    let newSheet = workBook.Sheets[sheetName];
    let excelJSON = XLSX.utils.sheet_to_json(newSheet) ? XLSX.utils.sheet_to_json(newSheet, { defval: '' }) : null;
    let columns = excelJSON && excelJSON[0] ? Object.keys(excelJSON[0]) : [];
    setExcelColumns(columns);
    setSelectedMobileColumn(null);
    setSelectedNameColumn(null);
  }

  if (!includes(['owner', 'admin'], currentUser?.role)) {
    return (
      <StackedLayout
        loading={loading}
        sidebarNavigation={sidebarNavigation}
        currentSidebarNavigation="Bulk admission"
      >
        <UserPermissionDeniedPage />
      </StackedLayout>
    )
  }

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="Bulk admission"
    >
      {showConfirmModal &&
        <ConfirmModal
          title="Confirm admission"
          description="Are you sure to confirm admission for this course? Please double check before performing this action."
          actionName="Confirm"
          onConfirm={handleEnrollCourses}
          onCancel={() => setShowConfirmModal(false)}
        />}
      <div className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
        <div>
          {!includes(['owner', 'admin'], currentUser?.role) && !currentBranch?.permissions?.canAdmitCentralStudents &&
            <div className="flex items-center space-x-1 text-yellow-600 mb-4">
              <ExclamationTriangleIcon className="h-5 w-5" />
              <p>You are not allowed to take admissions for other branches.</p>
            </div>}
          <div>
            <UploadWithDragDrop
              label="Upload excel file"
              actionName="Upload"
              contentType='excel'
              content={selectedFiles?.length > 0 ? selectedFiles[0] : null}
              onUpload={(files) => handleExcelUpload(files)}
            />
          </div>

          {excelSheets?.length > 0 &&
            <div>
              <dl className="mt-6 space-y-4 border-gray-200 text-sm leading-6">
                <div className="sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Sheet name</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="w-full">
                      <Select value={selectedSheet} options={excelSheets} onChange={(event) => selectSheetValue(event)} />
                    </div>
                  </dd>
                </div>

                <div className="sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Mobile column</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="w-full">
                      <Select value={selectedMobileColumn} options={excelColumns} onChange={(event) => setSelectedMobileColumn(event.target.value)} />
                    </div>
                  </dd>
                </div>

                <div className="sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Name column</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="w-full">
                      <Select value={selectedNameColumn} options={excelColumns} onChange={(event) => setSelectedNameColumn(event.target.value)} />
                    </div>
                  </dd>
                </div>

                {selectedSheet && selectedMobileColumn && selectedNameColumn && (selectedMobileColumn !== selectedNameColumn) &&
                  <>
                    <div className="sm:flex">
                      <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Program</dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="w-full">
                          <Select value={program?._id} options={programs} onChange={(event) => setProgram(programs[event.target.selectedIndex - 1])} />
                        </div>
                      </dd>
                    </div>

                    <div className="sm:flex">
                      <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Branch</dt>
                      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                        <div className="w-full">
                          <Select value={branch?._id} options={branches} onChange={(event) => setBranch(branches[event.target.selectedIndex - 1])} />
                        </div>
                      </dd>
                    </div>
                  </>
                }

                {program && branch &&
                  <div>
                    <fieldset className="border-b border-t border-gray-200">
                      <div className="divide-y divide-gray-200">
                        {courses?.map((course) => (
                          <div key={course._id} className="relative flex items-start pb-4 pt-3.5">
                            <div className="min-w-0 flex-1 text-sm leading-6">
                              <label className="font-medium text-gray-900">
                                {course.name}
                              </label>
                              <p className="text-gray-500">
                                <span>Fee: {course.isFree ? 'Free' : `${course.fee}/${program.paymentCircle}`}</span>
                                {program.paymentCircle === 'onetime' &&
                                  <>
                                    <span> &bull; </span>
                                    <span>Discount: {getDiscount(course)}</span>
                                  </>}
                                <span> &bull; </span>
                                {courseType === 'offline' ?
                                  <span>Payment mode: {program.paymentCircle === 'month' ? 'monthly' : 'onetime'}</span>
                                  : <span>Course type: {course.courseType === 'exam' ? 'Online exam' : capitalize(course.courseType)}</span>}
                              </p>

                              {isCourseSelected(course._id) && !isCourseEnrolled(course._id) &&
                                <div className="mt-2 grid grid-cols-1 sm:grid-cols-3 gap-x-2">
                                  {includes(['offline', 'live'], course.courseType) &&
                                    <Select
                                      col={1}
                                      label="Select batch"
                                      value={getSelectedBatch(course._id)}
                                      options={getCourseBatches(course._id)}
                                      onChange={(event) => handleSelectBatch(course._id, event.target.value)}
                                    />}

                                  {program.paymentCircle === 'onetime' &&
                                    <Input
                                      col={1}
                                      type="number"
                                      min="0"
                                      label="Special discount"
                                      value={getSpecialDiscountForCourse(course._id) || ''}
                                      onChange={(event) => handleChangeSpecialDiscount(course._id, event.target.value)}
                                    />}

                                  {program.paymentCircle === 'onetime' &&
                                    <Input col={1} disabled label="Amount" value={getCourseAmount(course)} />}

                                  {program.paymentCircle === 'month' &&
                                    <Input col={1} disabled type="month" label="Start month" value={getBatchStartMonth(course._id)} />}

                                  {program.paymentCircle === 'month' &&
                                    <Input col={1} disabled type="month" label="End month" value={getBatchEndMonth(course._id)} />}
                                </div>}
                            </div>
                            <div className="ml-3 flex h-6 items-center">
                              <input
                                type="checkbox"
                                disabled={isCourseEnrolled(course._id)}
                                checked={isCourseSelected(course._id)}
                                onChange={() => handleSelectCourse(course)}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>}
              </dl>
            </div>}
        </div>

        {selectedCourses?.length > 0 &&
          <div className="mt-10 lg:mt-0">
            <h2 className="text-lg font-medium text-gray-900">Payment summary</h2>
            <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
              <div className="flex flex-col px-4 py-6 sm:px-6">
                <p className="font-medium text-gray-700 hover:text-gray-800">
                  Selected courses:
                </p>
                <div className="ml-4 text-sm">
                  <ul className="">
                    {selectedCourses.map((selectedCourse, index) => (
                      <li
                        key={selectedCourse.course._id}
                        className=""
                      >
                        {index + 1}. {selectedCourse.course.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <dl className="space-y-4 border-t border-gray-200 px-4 py-6 sm:px-6">
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Course fee</dt>
                  <dd className="text-sm font-medium text-gray-900">৳{totalCourseFee.toLocaleString()}</dd>
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-sm">Promotional discount</dt>
                  <dd className="text-sm font-medium text-gray-900">৳{discount?.toLocaleString()}</dd>
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-sm font-semibold">Sub-total</dt>
                  <dd className="text-sm font-semibold text-gray-900">৳{(totalCourseFee - discount).toLocaleString()}</dd>
                </div>
                <div className="flex items-center justify-between">
                  <dt className="text-sm">{program.paymentCircle === 'onetime' ? `Special discount(s)` : `Monthly scholarship`}</dt>
                  {program.paymentCircle === 'onetime' ?
                    <dd className="text-sm font-medium text-gray-900">৳{specialDiscount?.toLocaleString()}</dd>
                    :
                    <dd className="text-sm font-medium text-gray-900">
                      <Input type="number" textRight value={monthlyScholarship} onChange={(event) => setMonthlyScholarship(event.target.value)} />
                    </dd>}
                </div>

                {program.paymentCircle === 'month' &&
                  <div className="flex items-center justify-between">
                    <dt className="text-sm">Billing start month</dt>
                    <dd className="text-sm font-medium text-gray-900">
                      <Input
                        col={1}
                        type="month"
                        value={startMonth || ''}
                        onChange={(event) => setStartMonth(event.target.value)}
                      />
                    </dd>
                  </div>}

                {program.paymentCircle === 'onetime' &&
                  <div className="flex items-center justify-between">
                    <dt className="text-sm">
                      <InlineCheckbox label="Include VAT (15%)" checked={includeVat} onChange={() => setIncludeVat(!cloneDeep(includeVat))} />
                    </dt>
                    <dd className="text-sm font-semibold text-gray-900">৳{vat.toLocaleString()}</dd>
                  </div>}
                <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt className="text-base font-medium">Total {program.paymentCircle === 'month' ? '(monthly fee)' : ''}</dt>
                  <dd className="text-base font-medium text-gray-900">৳{totalAmount.toLocaleString()}</dd>
                </div>

                {!programEnrolled && program.admissionFeeEnabled &&
                  <>
                    <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                      <dt className="text-base font-medium">Admission fee</dt>
                      <dd className="text-base font-medium text-gray-900">৳{program.admissionFee.toLocaleString()}</dd>
                    </div>

                    <div className="flex items-center justify-between">
                      <dt className="text-sm">Discount on admission fee</dt>
                      <dd className="text-sm font-semibold text-gray-900">
                        <Input type="number" textRight value={discountOnAdmissionFee} onChange={(event) => setDiscountOnAdmissionFee(event.target.value)} />
                      </dd>
                    </div>
                    <div className="flex items-center justify-between">
                      <dt className="text-sm">Admission fee payment</dt>
                      <dd className="text-sm font-semibold text-gray-900">{(program.admissionFee - parseFloat(discountOnAdmissionFee)).toLocaleString()}</dd>
                    </div>
                  </>}

                <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt className="text-sm">Reference</dt>
                  <dd className="text-sm font-medium text-gray-900 w-2/3">
                    <Input value={reference || ''} onChange={(event) => setReference(event.target.value)} />
                  </dd>
                </div>
              </dl>
              {/* } */}

              <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                <SubmitButton
                  disabled={isDisabled()}
                  className="w-full"
                  label="Confirm admission"
                  onClick={() => setShowConfirmModal(true)}
                />
              </div>
            </div>
          </div>}
      </div>
    </StackedLayout>
  )
};
