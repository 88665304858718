import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { cloneDeep } from 'lodash';
import { DocumentIcon } from '@heroicons/react/24/solid';

import StackedLayout from '../../layouts/StackedLayout';
import { Button } from '../../components/buttons';
import Form from '../../components/forms/FormLayout';
import FieldSet from '../../components/forms/fieldSets/TwoColumnFieldSet';
import { InlineCheckbox, Input, Select, TextArea, UploadWithDragDrop } from '../../components/forms/fields';
import { PlusButton } from '../../components/buttons';
import ChapterForm from './components/EbookChapterForm';
import AuthorForm from './components/EbookAuthorForm';
import { uploadEbook } from '../../services/upload';
import { getDownloadableLink } from '../../services/download';
import { getSubjects } from '../../services/subject';
import { getEbook, createEbook, updateEbook } from '../../services/ebook';


const grades = [
  { name: 'SSC', value: 'ssc' },
  { name: 'HSC', value: 'hsc' },
  { name: 'Admission', value: 'admission' },
  { name: 'Job preparation', value: 'job' }
];

const groups = [
  { name: 'All', value: 'all' },
  { name: 'Science', value: 'science' },
  { name: 'Business Studies', value: 'commerce' },
  { name: 'Humanities', value: 'humanities' }
];

let defaultChapters = [
  { name: '', startPage: 1 }
];

let defaultAuthors = [
  { name: '' }
];

export default function EbookEditPage() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const ebookId = params.id;
  const folderId = location?.state?.folderId;
  const [ebook, setEbook] = useState(null);
  const [name, setName] = useState(null);
  const [grade, setGrade] = useState(null);
  const [group, setGroup] = useState(null);
  const [pages, setPages] = useState(1);
  const [edition, setEdition] = useState(null);
  const [description, setDescription] = useState(null);
  const [chapters, setChapters] = useState(defaultChapters);
  const [pdf, setPdf] = useState(null);
  const [demoPdf, setDemoPdf] = useState(null);
  const [subject, setSubject] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [authors, setAuthors] = useState(defaultAuthors);
  const [changePdf, setChangePdf] = useState(false);
  const [changeDemoPdf, setChangeDemoPdf] = useState(false);
  const [isDownloadable, setIsDownloadable] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        if (ebookId) {
          const _ebook = await getEbook(ebookId);
          setEbook(_ebook);
          setName(_ebook.name);
          setGrade(_ebook.grade);
          setGroup(_ebook.group);
          setPages(_ebook.pages);
          setEdition(_ebook.edition);
          setDescription(_ebook.description);
          setChapters(_ebook.chapters);
          setPdf(_ebook.pdf);
          setDemoPdf(_ebook.demoPdf);
          setSubject(_ebook?.subject?._id);
          setIsDownloadable(_ebook?.isDownloadable);
          if (_ebook?.authors?.length > 0) {
            setAuthors(_ebook.authors);
          }
        }
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  useEffect(() => {
    async function _fetchSubject() {
      await fetchSubjects(grade);
    };
    _fetchSubject();
  }, [grade]);

  const fetchSubjects = async (_grade) => {
    try {
      let params = { status: 'active', grade: _grade || grade };
      if (group && group !== 'all') {
        params.group = ['all', group];
      }
      const _subjects = await getSubjects(params);
      setSubjects(_subjects);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const addChapter = (event) => {
    event.preventDefault();
    const _chapters = cloneDeep(chapters);
    const newItem = { name: '', startPage: 0 };
    _chapters.push(newItem);
    setChapters(() => _chapters);
  }

  const deleteChapter = (event, index) => {
    event.preventDefault();
    const _chapters = cloneDeep(chapters);
    _chapters.splice(index, 1);
    setChapters(() => _chapters);
  }

  const handleChapterFieldChange = (event, index) => {
    event.preventDefault();
    const _chapters = cloneDeep(chapters);
    const fieldName = event.target.name === 'chapter' ? 'name' : event.target.name;
    _chapters[index][fieldName] = event.target.value;
    setChapters(_chapters);

  };

  const addAuthor = (event) => {
    event.preventDefault();
    const _authors = cloneDeep(authors);
    const newItem = { name: '' };
    _authors.push(newItem);
    setAuthors(() => _authors);
  }

  const deleteAuthor = (event, index) => {
    event.preventDefault();
    const _authors = cloneDeep(authors);
    _authors.splice(index, 1);
    setAuthors(() => _authors);
  }

  const handleAuthorFieldChange = (event, index) => {
    event.preventDefault();
    const _authors = cloneDeep(authors);
    const fieldName = event.target.name === 'author' ? 'name' : event.target.name;
    _authors[index][fieldName] = event.target.value;
    setAuthors(_authors);

  };

  const handleEbookUpload = async (files) => {
    try {
      const data = new FormData();
      if (files.length > 0) {
        data.append('file', files[0]);
        const responseData = await uploadEbook(data);
        const ebookLink = responseData.ebookLink;
        setPdf(ebookLink);
        setChangePdf(false);
        return ebookLink;
      } else {
        throw new Error('No file attached');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDemoPdfUpload = async (files) => {
    try {
      const data = new FormData();
      if (files.length > 0) {
        data.append('file', files[0]);
        const responseData = await uploadEbook(data);
        const ebookLink = responseData.ebookLink;
        setDemoPdf(ebookLink);
        setChangeDemoPdf(false);
        return ebookLink;
      } else {
        throw new Error('No file attached');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEbookPreview = async (event) => {
    try {
      event.preventDefault();
      const responseData = await getDownloadableLink({ privateLink: pdf });
      window.open(responseData.publicLink);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDemoPreview = async (event) => {
    try {
      event.preventDefault();
      const responseData = await getDownloadableLink({ privateLink: demoPdf });
      window.open(responseData.publicLink);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const saveEbook = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      let payload = {
        name,
        grade,
        group,
        pdf,
        demoPdf,
        description,
        chapters,
        pages,
        edition,
        subject,
        authors,
        isDownloadable
      };
      if (ebookId) {
        await updateEbook(ebookId, payload);
        toast.success(`Ebook updated successfully.`);
      } else {
        if (folderId) { payload.folder = folderId; }
        await createEbook(payload);
        toast.success(`Ebook created successfully.`);
        goBack();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const goBack = () => {
    if (folderId || ebook?.folder) {
      navigate(`/contents/documents/folder/${folderId || ebook?.folder}`);
    } else {
      navigate(`/contents/documents`);
    }
  };

  const isDisabled = () => {
    return updating || !name || !pdf;
  }

  return (
    <StackedLayout loading={loading}>
      <div className="flex justify-between border-b border-gray-200 pb-6">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            {ebook?.name || 'Create new ebook'}
          </h1>
          {ebook &&
            <p className="mt-2 text-base text-gray-500">
              Edit information for this ebook.
            </p>}
        </div>

        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button label="Go back" onClick={goBack} />
        </div>
      </div>

      <Form
        submitText={ebook ? 'Save' : 'Create'}
        disabled={isDisabled()}
        onSubmit={saveEbook}
        onCancel={goBack}
      >
        <FieldSet
          title="Ebook info"
          subTitle="Give ebook name, set grade and group."
        >
          <Input col={4} label="Name" name="name" value={name} onChange={(event) => setName(event.target.value)} />
          <Input type="number" col={1} label="Pages" name="pages" value={pages} onChange={(event) => setPages(event.target.value)} />

          {/* <Select col={2} label="Grade" value={grade} options={grades} onChange={(event) => setGrade(event.target.value)}/>
          <Select col={2} disabled={grade === 'job'} label="Group" name="group" value={group} options={groups} onChange={(event) => setGroup(event.target.value)}/>
          <span></span><span></span> */}
          {/* <Input col={2} label="Edition" name="edition" value={edition} onChange={(event) => setEdition(event.target.value)}/> */}
          {/* <Select label="Subject" name="subject" value={subject} options={subjects} onChange={(event) => setSubject(event.target.value)} /> */}
          <TextArea label="Description" name="description" value={description} onChange={(event) => setDescription(event.target.value)} />
        </FieldSet>

        <FieldSet
          title="Download configurations"
          subTitle="Allow students to download this note"
        >
          <InlineCheckbox
            label="Can student download?"
            description="You can allow students to download this note"
            checked={isDownloadable}
            onChange={() => setIsDownloadable(!cloneDeep(isDownloadable))}
          />
        </FieldSet>

        <FieldSet
          title="Upload ebook"
          subTitle="Upload pdf file for ebook."
        >
          {pdf && !changePdf &&
            <div className="flex col-span-full justify-between">
              <div className="flex justify-start space-x-4">
                <DocumentIcon className="h-6 w-6 text-gray-500" />
                <p>Ebook full copy</p>
              </div>

              <div className="justify-end space-x-4">
                <button
                  className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={(event) => handleEbookPreview(event)}
                >
                  Preview
                </button>

                <button
                  className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
                  onClick={() => setChangePdf(true)}
                >
                  Change
                </button>
              </div>
            </div>
          }

          {(!pdf || changePdf) &&
            <UploadWithDragDrop
              contentType="pdf"
              content={pdf}
              label="Full ebook (PDF)"
              actionName="Upload a file"
              onUpload={(files) => handleEbookUpload(files)}
              showCancelButton={changePdf}
              onCancel={() => setChangePdf(false)}
            />}
        </FieldSet>

        {/* <FieldSet
          title="Upload preview file"
          subTitle="Upload pdf file to provide a snapshot to the user."
        >
          {demoPdf && !changeDemoPdf &&
            <div className="flex col-span-full justify-between">
              <div className="flex justify-start space-x-4">
                <DocumentIcon className="h-6 w-6 text-gray-500" />
                <p>Ebook preview copy</p>
              </div>

              <div className="justify-end space-x-4">
                <button
                  className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={(event) => handleDemoPreview(event)}
                >
                  Preview
                </button>

                <button
                  className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
                  onClick={() => setChangeDemoPdf(true)}
                >
                  Change
                </button>
              </div>
            </div>
          }

          {(!demoPdf || changeDemoPdf) &&
          <UploadWithDragDrop
            contentType="pdf"
            content={demoPdf} 
            label="Ebook preview copy (PDF)" 
            actionName="Upload a file"
            onUpload={(files) => handleDemoPdfUpload(files)}
            showCancelButton={changeDemoPdf}
            onCancel={() => setChangeDemoPdf(false)}
          />}
        </FieldSet> */}

        {/* <FieldSet
          title="Chapter set up"
          subTitle="Set up name, start page for each chapter. "
        >
          {chapters?.length > 0 && chapters.map((chapter, index) => (
            <ChapterForm
              key={`chapter-settings-index[${index}]`}
              chapterCount={chapters.length} 
              chapter={chapter} 
              index={index}
              onDelete={deleteChapter} 
              onFieldChange={handleChapterFieldChange}
            />
          ))}
          <div className="col-span-2">
            <PlusButton label="Add chapter" onClick={addChapter} />
          </div>
        </FieldSet> */}

        {/* <FieldSet
          title="Autthor set up"
          subTitle="Set up name, photo for each author. "
        >
          {authors?.length > 0 && authors.map((author, index) => (
            <AuthorForm
              key={`author-settings-index[${index}]`}
              authorCount={authors.length} 
              author={author} 
              index={index}
              onDelete={deleteAuthor} 
              onFieldChange={handleAuthorFieldChange}
            />
          ))}
          <div className="col-span-2">
            <PlusButton label="Add author" onClick={addAuthor} />
          </div>
        </FieldSet> */}
      </Form>

    </StackedLayout>
  )
};
