import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { omit, isEmpty, findIndex, cloneDeep, capitalize, forEach, includes } from 'lodash';

import CategoryListingLayout from '../../layouts/CategoryListingLayout';
import Table from '../../components/tables/StackedTable';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import CourseFeePanel from './components/CourseFeePanel';
import ChapterForm from './components/ChapterForm';
import BranchForm from './components/BranchForm';
import AssoicatedCoursesForm from './components/AssociatedCoursesForm';
import {
  getCourses,
  updateCourse,
  changeCourseStatus
} from '../../services/course';
import { getPrograms } from '../../services/program';


const defaultFilters = [
  {
    name: 'Status',
    fieldName: 'status',
    options: [
      {label: 'Active', value: 'active'},
      {label: 'Inactive', value: 'inactive'}
    ]
  },
  {
    name: 'Course type',
    fieldName: 'courseType',
    options: [
      {label: 'Live', value: 'live'},
      {label: 'Recorded', value: 'recorded'},
      {label: 'Model test', value: 'exam'}
    ]
  }
];

let offlineCourseFilters = [
  {
    name: 'Status',
    fieldName: 'status',
    options: [
      {label: 'Active', value: 'active'},
      {label: 'Inactive', value: 'inactive'}
    ]
  }
];
const headers = ['Name', 'Program', 'Grade', 'Group', 'Type', 'Fee', 'Enrolled', 'Status'];
const offlineCourseHeaders = ['Name', 'Program', 'Grade', 'Group', 'Payment Circle', 'Fee', 'Enrolled', 'Status'];

export default function CoursesPage(props) {
  const courseType = props?.courseType;
  const [courses, setCourses] = useState([]);
  const [pagingData, setPagingData] = useState({});
  const [queryString, setQueryString] = useState(null);
  const [typeFilters, setTypeFilters] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);
  const [gradeFilters, setGradeFilters] = useState([]);
  const [programFilters, setProgramFilters] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [openCourseFeePanel, setOpenCourseFeePanel] = useState(false);
  const [openChapterForm, setOpenChapterForm] = useState(false);
  const [openBranchForm, setOpenBranchForm] = useState(false);
  const [openAssociatedCoursesForm, setOpenAssociatedCoursesForm] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(courseType === 'offline' ? offlineCourseFilters : defaultFilters);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();

      const programs = await getPrograms({ status: 'active' });
      let programFilterOptions = [];
      forEach(programs, program => programFilterOptions.push({
        label: program.name,
        value: program._id.toString()
      }));
      if (programFilterOptions.length > 0) {
        let _filters = cloneDeep(filters);
        _filters.push({
          name: 'Programs',
          fieldName: 'program',
          options: programFilterOptions
        });
        setFilters(_filters);
      }
      setLoading(false);
    }
    _fetchData();
  }, []);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
    }
    _fetchData();
  }, [
    typeFilters,
    statusFilters,
    gradeFilters,
    programFilters,
    queryString,
    currentPage
  ]);

  const fetchData = async () => {
    try {
      let params = {
        isPaging: true,
        limit: 10,
        page: currentPage,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        search: queryString
      };

      if (courseType === 'offline') {
        params.courseType = courseType;
      } else if (typeFilters.length > 0) {
        params.courseType = typeFilters.length === 1 ? typeFilters[0] : typeFilters;
      } else {
        params.courseType = ['recorded', 'live', 'exam'];
      }

      if (statusFilters.length > 0) {
        params.status = statusFilters;
      }
      if (gradeFilters.length > 0) {
        params.grade = gradeFilters.length === 1 ? gradeFilters[0] : gradeFilters;
      }
      if (programFilters.length > 0) {
        params.program = programFilters.length === 1 ? programFilters[0] : programFilters;
      }

      const data = await getCourses(params);
      setCourses(data?.docs || []);
      setPagingData(omit(data, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const onSearch = async (event, _queryString) => {
    event.preventDefault();
    const q = !isEmpty(_queryString) ? _queryString : null;
    setQueryString(q);
    setCurrentPage(1);
    setStatusFilters([]);
    setTypeFilters([]);
    setProgramFilters([]);
    setGradeFilters([]);
    setLoading(true);
  };

  const saveCourse = async (event, payload) => {
    try {
      event.preventDefault();
      await updateCourse(selectedCourse._id, payload);
      toast.success(`Course updated successfully.`);
      await fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleStatusChange = async (event) => {
    try {
      event.preventDefault();
      await changeCourseStatus(selectedCourse._id);
      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const applyFilterChanges = (event, filterIndex) => {
    const _value = event.target.value;
    const _typeFilters = cloneDeep(typeFilters);
    const _statusFilters = cloneDeep(statusFilters);
    const _gradeFilters = cloneDeep(gradeFilters);
    const _programFilters = cloneDeep(programFilters);
    const fieldName = filters[filterIndex].fieldName;

    if (fieldName === 'status') {
      const valueIndex = findIndex(_statusFilters, item => item === _value);
      if (valueIndex === -1) {
        _statusFilters.push(_value);
      } else {
        _statusFilters.splice(valueIndex, 1);
      }
      setStatusFilters(_statusFilters);
    } else if (fieldName === 'courseType') {
      const valueIndex = findIndex(_typeFilters, item => item === _value);
      if (valueIndex === -1) {
        _typeFilters.push(_value);
      } else {
        _typeFilters.splice(valueIndex, 1);
      }
      setTypeFilters(_typeFilters);
    } else if (fieldName === 'grade') {
      const valueIndex = findIndex(_gradeFilters, item => item === _value);
      if (valueIndex === -1) {
        _gradeFilters.push(_value);
      } else {
        _gradeFilters.splice(valueIndex, 1);
      }
      setGradeFilters(_gradeFilters);
    } else if (fieldName === 'program') {
      const valueIndex = findIndex(_programFilters, item => item === _value);
      if (valueIndex === -1) {
        _programFilters.push(_value);
      } else {
        _programFilters.splice(valueIndex, 1);
      }
      setProgramFilters(_programFilters);
    }
  };

  return (
    <CategoryListingLayout
      loading={loading}
      title={courseType === 'offline' ? 'Offline courses' : 'Online courses'}
      subTitle={`List of courses for your institution.`}
      // warningMessage={!onlineCourseEnabled && `Your courses will not appear in the online platform. Please contact sales to upgrade your account.` }
      hideFilters={(openChapterForm || openBranchForm || openAssociatedCoursesForm)}
      hideHeader={(openChapterForm || openBranchForm || openAssociatedCoursesForm)}
      filters={filters}
      onFilterChange={applyFilterChanges}
      onCreate={() => window.location.href = courseType === 'offline' ? '/courses/offline/create' : '/courses/online/create'}
    >
      {showStatusModal &&
        <ConfirmModal
          title={selectedCourse?.status === 'active' ? 'Disable course' : 'Enable course'}
          description={`Are you sure to ${selectedCourse?.status === 'active' ? 'disable' : 'enable'} this course? Please double check before performing this action.`}
          actionName={selectedCourse?.status === 'active' ? 'Disable' : 'Enable'}
          onConfirm={handleStatusChange}
          onCancel={() => {
            setSelectedCourse(null);
            setShowStatusModal(false);
          }}
        />}

      {openCourseFeePanel &&
        <CourseFeePanel
          course={selectedCourse}
          onSave={saveCourse}
          onClose={() => {
            setSelectedCourse(null);
            setOpenCourseFeePanel(false);
          }}
        />}

      {openChapterForm &&
        <ChapterForm
          courseType={selectedCourse.courseType}
          courseName={selectedCourse.name}
          chapters={selectedCourse?.chapters}
          onSave={saveCourse}
          onCancel={() => {
            setSelectedCourse(null);
            setOpenChapterForm(false);
          }}
        />}

      {openBranchForm &&
        <BranchForm
          course={selectedCourse}
          onSave={saveCourse}
          onCancel={() => {
            setSelectedCourse(null);
            setOpenBranchForm(false);
          }}
        />}

      {openAssociatedCoursesForm &&
        <AssoicatedCoursesForm
          course={selectedCourse}
          onSave={saveCourse}
          onCancel={() => {
            setSelectedCourse(null);
            setOpenAssociatedCoursesForm(false);
          }}
        />}

      {!openChapterForm && !openBranchForm && !openAssociatedCoursesForm &&
        <Table
          headers={courseType === 'offline' ? offlineCourseHeaders : headers}
          items={courses}
          itemsCount={courses.length}
          queryString={queryString}
          pagingData={pagingData}
          onSearch={onSearch}
          onPageChange={onPageChange}
        >
          {courses.map((course) => (
            <tr key={course._id} className="text-center">
              <td className="py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-0 text-ellipsis">
                {course.name}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">{course?.program?.name}</td>
              <td className="pr-3 py-4 text-sm text-gray-500">{course.grade}</td>
              <td className="pr-3 py-4 text-sm text-gray-500">{course.group}</td>
              {courseType !== 'offline' && <td className="pr-3 py-4 text-sm text-gray-500">{course.courseType}</td>}
              {courseType === 'offline' && <td className="pr-3 py-4 text-sm text-gray-500">{course.program.paymentCircle}</td>}
              <td className="pr-3 py-4 text-sm text-gray-500">{course?.isFree ? 'Free' : course.fee}</td>
              <td className="pr-3 py-4 text-sm text-gray-500">{course?.enrolledCount}</td>
              <td
                className={`pr-3 py-4 text-sm ${course.status === 'active' ? 'text-green-500' : 'text-red-500'}`}
              >
                {capitalize(course.status)}
              </td>

              <td className="relative py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <ActionItems
                  course={course}
                  onManageChapters={() => {
                    setSelectedCourse(course);
                    setOpenChapterForm(true);
                  }}
                  onManageBranches={() => {
                    setSelectedCourse(course);
                    setOpenBranchForm(true);
                  }}
                  onManageAssociatedCourses={() => {
                    setSelectedCourse(course);
                    setOpenAssociatedCoursesForm(true);
                  }}
                  onManageFees={() => {
                    setSelectedCourse(course);
                    setOpenCourseFeePanel(true);
                  }}
                  onStatusChange={() => {
                    setSelectedCourse(course);
                    setShowStatusModal(true);
                  }}
                />
              </td>
            </tr>
          ))}
        </Table>}
    </CategoryListingLayout>
  )
};

function ActionItems(props) {
  const {
    course,
    onManageBranches,
    onManageChapters,
    onManageFees,
    onManageAssociatedCourses,
    onStatusChange
  } = props;

  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Edit" href={`/${course.courseType === 'offline' ? 'courses/offline' : 'courses/online'}/${course._id}/edit`} />
      </div>
      <div className="py-1">
        <MenuItem label="Course outlines" onClick={onManageChapters} />

        {includes(['recorded', 'exam'], course.courseType) && course?.chapters?.length > 0 &&
          <MenuItem label="Manage contents" href={`/courses/online/${course._id}/contents`} />}

        {course.courseType === 'live' &&
          <MenuItem label="Manage batches" href={`/courses/online/${course._id}/batches`} />}

        {course.courseType === 'offline' &&
          <MenuItem label="Manage contents" href={`/courses/offline/${course._id}/contents`} />}

        {course.courseType === 'offline' &&
          <MenuItem label="Associated courses" onClick={onManageAssociatedCourses} />}
      </div>
      <div className="py-1">
        <MenuItem label="Manage course fee" onClick={onManageFees} />
        {/* {course.courseType === 'offline' && course.paymentCircle === 'month' &&
        <MenuItem label="Manage tuition fees" href={`/courses/offline/${course._id}/fees`} />} */}
      </div>

      {course.courseType === 'offline' &&
        <div className="py-1">
          <MenuItem label="Branch permissions" onClick={onManageBranches} />
        </div>}

      {course.courseType !== 'offline' &&
        <div className="py-1">
          <MenuItem label="Manage students" href={`/courses/online/${course._id}/students`} />
        </div>}

      <div className="py-1">
        <MenuItem label={course?.status === 'active' ? 'Disable' : 'Enable'} isDanger={course?.status === 'active'} onClick={onStatusChange} />
      </div>

    </ActionMenu>
  )

};
