import { useEffect, useState } from 'react';
import { Button } from '../../../components/buttons';
import Table from '../../../components/tables/StackedTable';
import { getSmsCounts } from '../../../utils';
import { cloneDeep, forEach } from 'lodash';


export default function SmsPreviewModal(props) {
  const {
    smsBody,
    contacts,
    isExcel,
    excelData,
    excelColumns,
    mobileColumn,
    onCancel
  } = props;
  const [excelMessages, setExcelMessages] = useState([])

  useEffect(() => {
    if (isExcel) {
      let _excelMessages = [];
      forEach(excelData, (row, index) => {
        let _smsBody = cloneDeep(smsBody);
        let smsData = { mobile: row[mobileColumn] }
        forEach(excelColumns, function (excelColumn, _index) {
          _smsBody = _smsBody.includes(`{{${excelColumn}[${_index}]}}`) ?
            _smsBody.split(`{{${excelColumn}[${_index}]}}`).join(excelData[index][excelColumn]) : _smsBody;
        });
        smsData.smsBody = _smsBody;
        _excelMessages.push(smsData);
      });
      setExcelMessages(_excelMessages);
    }
  }, [])

  return (
    <div>
      <div>
        <Table
          title="Sms preview panel"
          headers={['#', 'Mobile', 'Body', 'Charracter Count', 'Type', 'SMS Count']}
          items={contacts}
          itemsCount={isExcel ? excelMessages?.length : contacts.length}
        >
          {!isExcel && contacts?.map((contact, index) => (
            <tr key={contact} className="text-center">
              <td className="py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-0 text-ellipsis">
                {index + 1}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">{contact}</td>
              <td className="pr-3 py-4 text-sm text-gray-500">{smsBody}</td>
              <td className="pr-3 py-4 text-sm text-gray-500">{smsBody.length}</td>
              <td className="pr-3 py-4 text-sm text-gray-500">{getSmsCounts(smsBody).smsType}</td>
              <td className="pr-3 py-4 text-sm text-gray-500">{getSmsCounts(smsBody).smsCount}</td>
            </tr>
          ))}

          {isExcel && excelMessages?.map((data, index) => (
            <tr key={data.mobile} className="text-center">
              <td className="py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-0 text-ellipsis">
                {index + 1}
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">{data.mobile}</td>
              <td className="pr-3 py-4 text-sm text-gray-500">{data.smsBody}</td>
              <td className="pr-3 py-4 text-sm text-gray-500">{data?.smsBody?.length}</td>
              <td className="pr-3 py-4 text-sm text-gray-500">{getSmsCounts(data.smsBody).smsType}</td>
              <td className="pr-3 py-4 text-sm text-gray-500">{getSmsCounts(data.smsBody).smsCount}</td>
            </tr>
          ))}

        </Table>
      </div>
      <div className="mt-10">
        <Button label="Cancel" onClick={onCancel} />
      </div>
    </div>
  )
};
